import React, { useContext, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/navbar/navbar.css';
import { AuthContext } from '../../context/authAdmin.js';
import CartDrawer from './CartDrawer.jsx';
import Category from './category.jsx';
import { GiShoppingCart } from "react-icons/gi";
import { CartContext } from '../../context/cartContext.js';
import NavBarDrawer from './NavBarDrawer.jsx';
import LoginModal from '../loginModal.js';

function Navbar() {
    const [isCartOpen, setIsCartOpen] = useState(false);
    const { isLoggedIn } = useContext(AuthContext);
    const cartIconRef = useRef(null);
    const { cartQuantity } = useContext(CartContext);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  
    const toggleCart = () => {
      setIsCartOpen(!isCartOpen);
    };

    const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
    };
    const openLoginModal = () => {
      setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
      setIsLoginModalOpen(false);
  };
    return (
      <section  className="containerSection shapedividers_com-8609">
            
            <div className="containerTotal">
   
              <div className='containerNav'>
                <nav >

                  <div className='drawer-nav-container'>
                      
                  <div className={`menu-toggle ${isDrawerOpen ? 'open' : ''}`} onClick={toggleDrawer}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                  </div>

                      <NavBarDrawer isOpen={isDrawerOpen} onClose={toggleDrawer} openLogin={openLoginModal} />
                  </div>

                  <div className="logo">
                    <Link to="/">
                      <picture>
                        <img src="/logomain.jpeg" alt="ALFILDIGITAL" />
                      </picture>
                    </Link>
                  </div>

                  <div className='navRightSide'>
                                                    
                    <div className='containerCategory'>
                      <Category />
                    </div>

                    <div className="nav-links">
                      <ul>
                        {isLoggedIn ? (
                          <li className='panel-login'>
                            <Link to="/admin">Panel Admin</Link>
                          </li>
                        ) : (
                          <li>
                            <button className="btn" onClick={openLoginModal}>
                            </button>
                          </li>
                        )}
                           {!isLoggedIn && (
                              <div className="cart-icon" onClick={toggleCart} ref={cartIconRef}>
                                <div>
                                <GiShoppingCart />
                                </div>
                                <div className="notification">
                                  {cartQuantity > 0 && <span >{cartQuantity}</span>}
                                </div>

                              </div>
                           )}
                      </ul>
                    </div>

                 

                    <div className='itemsRight'>
                      <div className='itemLogin'>
              

                      </div>

                      <div className='itemCart'>
                        <CartDrawer isOpen={isCartOpen} onClose={toggleCart} cartIconRef={cartIconRef} />
                      </div>
                    </div>
                  
                  </div>

                </nav>
               
              </div>
            </div>
            <LoginModal isOpen={isLoginModalOpen} onClose={closeLoginModal} />
       </section>
  
    );
  }
  
  export default Navbar;

